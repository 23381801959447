import React from "react"

import { Router } from "@reach/router"

import { graphql } from "gatsby"

import Downloads from "../../components/downloads"

// ? Dynamic app stuff
import Layout from "./layout"
import PrivateRoute from "./privateRoute"

const AssetLibrary = ({ data }) => {
  const PrivateDownloads = () => (
    <Downloads data={data.allDatoCmsFilecategory.edges} />
  )

  return (
    <>
      <Layout>
        <Router>
          <PrivateRoute path="/asset-library/" component={PrivateDownloads} />
        </Router>
      </Layout>
    </>
  )
}

export default AssetLibrary

export const query = graphql`
  query {
    allDatoCmsFilecategory(sort: { fields: position }) {
      edges {
        node {
          position
          id
          categoryName
          privateAsset
          files {
            isImage
            createdAt
            url
            path
            alt
            title
            format
          }
        }
      }
    }
  }
`
