import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"

const PrivateRoute = ({ location, component: Component, ...rest }) => {
  useEffect(() => {
    console.log("location", location)

    if (!isLoggedIn() && location.pathname !== `asset-library/`) {
      navigate(`/asset-library/`)
      return
    }
  }, [])

  return isLoggedIn() ? <Component {...rest} /> : null
}

export default PrivateRoute
